var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.subscriptionObject ? _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": function ($event) {
        _vm.validate();
        _vm.$emit('validate');
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('h2', {
    staticClass: "mb-6"
  }, [_vm._v(" " + _vm._s(_vm.$t('subscription')) + " ")]), _c('v-row', [!_vm.createNewObject ? _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.offers,
      "item-text": `title.${_vm.$userLocale}`,
      "item-value": "_id",
      "label": _vm.$t('plan'),
      "outlined": "",
      "attach": ""
    },
    model: {
      value: _vm.subscriptionObject.plan,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "plan", $$v);
      },
      expression: "subscriptionObject.plan"
    }
  })], 1) : _vm._e(), _c('v-col', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.greaterThanOrEqualZero],
      "label": _vm.$t('price'),
      "min": "0",
      "outlined": "",
      "append-icon": "fas fa-euro-sign"
    },
    model: {
      value: _vm.subscriptionObject.price,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "price", _vm._n($$v));
      },
      expression: "subscriptionObject.price"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.paymentMethods,
      "label": _vm.$t('paymentMethod'),
      "outlined": "",
      "attach": ""
    },
    model: {
      value: _vm.subscriptionObject.payment_method,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "payment_method", $$v);
      },
      expression: "subscriptionObject.payment_method"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.recurrence,
      "label": _vm.$t('recurrence'),
      "outlined": "",
      "attach": ""
    },
    model: {
      value: _vm.subscriptionObject.recurrence,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "recurrence", $$v);
      },
      expression: "subscriptionObject.recurrence"
    }
  })], 1), !_vm.createNewObject ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('presetPurchaseOrder'),
      "outlined": ""
    },
    model: {
      value: _vm.subscriptionObject.purchaseOrder,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "purchaseOrder", $$v);
      },
      expression: "subscriptionObject.purchaseOrder"
    }
  })], 1) : _vm._e()], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('presetDiscountCode'),
      "outlined": ""
    },
    model: {
      value: _vm.subscriptionObject.code,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "code", $$v);
      },
      expression: "subscriptionObject.code"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.greaterThanOrEqualZero],
      "label": _vm.$t('trialPeriod'),
      "min": "0",
      "outlined": ""
    },
    model: {
      value: _vm.subscriptionObject.trial_period,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "trial_period", _vm._n($$v));
      },
      expression: "subscriptionObject.trial_period"
    }
  })], 1)], 1), _c('v-row', [!_vm.createNewObject ? _c('v-col', [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('autoRenewal'),
      "inset": "",
      "color": "success"
    },
    model: {
      value: _vm.subscriptionObject.auto_renewal,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "auto_renewal", $$v);
      },
      expression: "subscriptionObject.auto_renewal"
    }
  })], 1) : _vm._e(), !_vm.createNewObject ? _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('nextBillingDate'),
      "outlined": "",
      "disabled": true
    },
    model: {
      value: _vm.subscriptionObject.nextBillingDate,
      callback: function ($$v) {
        _vm.$set(_vm.subscriptionObject, "nextBillingDate", $$v);
      },
      expression: "subscriptionObject.nextBillingDate"
    }
  })], 1) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }