var render = function render(){
  var _vm$offerObject, _vm$offerObject2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.offerObject ? _c('v-form', {
    ref: "form",
    staticClass: "mb-5",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('validate');
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('id'),
      "outlined": "",
      "rules": [_vm.rules.required],
      "required": ""
    },
    model: {
      value: _vm.offerObject._id,
      callback: function ($$v) {
        _vm.$set(_vm.offerObject, "_id", $$v);
      },
      expression: "offerObject._id"
    }
  }), _c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('title')) + " ")]), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('en'),
      "outlined": "",
      "rules": [_vm.rules.required],
      "required": ""
    },
    model: {
      value: _vm.offerObject.title.en,
      callback: function ($$v) {
        _vm.$set(_vm.offerObject.title, "en", $$v);
      },
      expression: "offerObject.title.en"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('fr'),
      "outlined": "",
      "rules": [_vm.rules.required],
      "required": ""
    },
    model: {
      value: _vm.offerObject.title.fr,
      callback: function ($$v) {
        _vm.$set(_vm.offerObject.title, "fr", $$v);
      },
      expression: "offerObject.title.fr"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('display')
    },
    model: {
      value: _vm.offerObject.display,
      callback: function ($$v) {
        _vm.$set(_vm.offerObject, "display", $$v);
      },
      expression: "offerObject.display"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('displayOrder'),
      "outlined": "",
      "rules": [_vm.rules.displayOrder],
      "required": "",
      "min": "0",
      "max": "99",
      "disabled": !_vm.offerObject.display
    },
    model: {
      value: _vm.offerObject.displayOrder,
      callback: function ($$v) {
        _vm.$set(_vm.offerObject, "displayOrder", _vm._n($$v));
      },
      expression: "offerObject.displayOrder"
    }
  })], 1)], 1), (_vm$offerObject = _vm.offerObject) !== null && _vm$offerObject !== void 0 && _vm$offerObject.features ? _c('form-warehouse-features', {
    ref: "formWarehouseFeatures",
    staticClass: "mb-6",
    attrs: {
      "features": _vm.offerObject.features,
      "brands": _vm.brands,
      "suppliers": _vm.suppliers,
      "themes": _vm.themes,
      "startup-feature-available": _vm.offerObject.subscription.plan === 'STARTUP',
      "create-new-object": _vm.createNewObject
    },
    on: {
      "validate": function ($event) {
        _vm.validate();
        _vm.$emit('validate');
      }
    }
  }) : _vm._e(), (_vm$offerObject2 = _vm.offerObject) !== null && _vm$offerObject2 !== void 0 && _vm$offerObject2.subscription ? _c('form-warehouse-subscription', {
    ref: "formWarehouseSubscription",
    attrs: {
      "subscription": _vm.offerObject.subscription,
      "offers": _vm.offers,
      "create-new-object": _vm.createNewObject
    },
    on: {
      "validate": function ($event) {
        _vm.validate();
        _vm.$emit('validate');
      }
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }