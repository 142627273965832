<template>
  <v-form
    v-if="offerObject"
    ref="form"
    v-model="valid"
    lazy-validation
    class="mb-5"
    @input="$emit('validate')"
  >
    <!-- ID -->
    <v-text-field
      v-model="offerObject._id"
      :label="$t('id')"
      outlined
      :rules="[rules.required]"
      required
    ></v-text-field>
    <!-- TITLE -->
    <h3 class="mb-2">
      {{ $t('title') }}
    </h3>
    <v-row>
      <v-col>
        <!-- Title En -->
        <v-text-field
          v-model="offerObject.title.en"
          :label="$t('en')"
          outlined
          :rules="[rules.required]"
          required
        ></v-text-field>
      </v-col>
      <v-col>
        <!-- Title Fr -->
        <v-text-field
          v-model="offerObject.title.fr"
          :label="$t('fr')"
          outlined
          :rules="[rules.required]"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Display Information -->
    <v-row>
      <v-col>
        <!-- Display Switch -->
        <v-switch
          v-model="offerObject.display"
          :label="$t('display')"
        ></v-switch>
      </v-col>
      <v-col>
        <!-- Display Order -->
        <v-text-field
          v-model.number="offerObject.displayOrder"
          :label="$t('displayOrder')"
          outlined
          :rules="[rules.displayOrder]"
          required
          min="0"
          max="99"
          :disabled="!offerObject.display"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Features -->
    <form-warehouse-features
      v-if="offerObject?.features"
      ref="formWarehouseFeatures"
      class="mb-6"
      :features="offerObject.features"
      :brands="brands"
      :suppliers="suppliers"
      :themes="themes"
      :startup-feature-available="offerObject.subscription.plan === 'STARTUP'"
      :create-new-object="createNewObject"
      @validate="validate(); $emit('validate')"
    ></form-warehouse-features>
    <!-- Subscription -->
    <form-warehouse-subscription
      v-if="offerObject?.subscription"
      ref="formWarehouseSubscription"
      :subscription="offerObject.subscription"
      :offers="offers"
      :create-new-object="createNewObject"
      @validate="validate(); $emit('validate')"
    >
    </form-warehouse-subscription>
  </v-form>
</template>

<script>

import FormWarehouseFeatures from '../formWarehouseFeatures/FormWarehouseFeatures.vue'; 
import FormWarehouseSubscription from '../formWarehouseSubscription/FormWarehouseSubscription.vue'; 

const i18nData = require('./warehouseOffer.i18n');
export default {
  name: 'WarehouseOffer',
  components: {
    FormWarehouseFeatures,
    FormWarehouseSubscription,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * The offer object
     */
    offer: {
      type: Object,
      required: true
    },
    /**
     * New object
     * Disable some fields if we add a new object instead of edit a current object
     */
    createNewObject: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    offer: {
      handler: function() {
        this.init();
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      /**
       * Offer Object
       */
      offerObject: null,
      /**
       * All Brands
       */
      brands: [],
      /**
       * All Suppliers
       */
      suppliers: [],
      /**
       * All Offers
       */
      offers: [],
      /**
       * All Themes
       */
      themes: [],
      /**
       * Form validation status
       */
      valid: false,
      /**
       * All Form validation status
       */
      isValid: true,
      /**
       * Form validation rules
       */
      rules: {
        required: (value) =>
          (value && value.toString().trim() !== '') || this.$t('requiredField'),
        displayOrder: (value) =>
          (!isNaN(Number(value)) && Number.parseInt(value) >= 0 && Number.parseInt(value) <= 99) || this.$t('invalidDisplayOrder'),
      },

    };
  },
  created() {
    
  },
  mounted() {
    /**
     * Reset validation
     */
    this.$refs.form.resetValidation();
    this.$refs.formWarehouseFeatures.resetValidation();
    this.$refs.formWarehouseSubscription.resetValidation();
  },
  methods: {
    init() {
      const offerObject = JSON.parse(JSON.stringify(this.offer));
      const defaultObject = {
        _id: '',
        title: {
          en: '',
          fr: ''
        },
        display: false,
        displayOrder: 99,
        features: {
          accessToBCMNetwork: true,
          warehousePartQuota: 100,
          availableDeliveryCountries: [
            'FR'
          ],
          userQuota: 100,
          enableTags: false,
          forcePurchaseOrder: false,
          defaultPaymentMethod: 'card',
          defaultMargin: 12,
          startup: {
            isManager: false,
            managedBy: null
          },
          sharing: {
            enabled: false,
            sharingWith: []
          },
          enableSerialNumber: false,
          privateSuppliers: [],
          quoteCalculation: 'REAL_PRICE',
          deliveryDiscount: 0
        },
        subscription: {
          recurrence: 'MONTHLY',
          payment_method: 'CARD',
          plan: null,
          price: 0,
          purchaseOrder: '',
          nextBillingDate: null,
          code: '',
          auto_renewal: true,
          trial_period: 0,
          syncInvoiceUUID: ''
        }
      };
      this.fillObjectWithDefaultValue(offerObject, defaultObject);
      if(!offerObject.subscription.plan) {
        offerObject.subscription.plan = offerObject._id;
      }
      /**
       * Update
       */
      this.offerObject  = offerObject;
      /**
       * Get all brands
       */
      this.getAllBrands();
      /**
       * Get all suppliers
       */
      this.getAllSuppliers();
      /**
       * Get all offers
       */
      this.getAllOffers();
      /**
       * Get all themes
       */
      this.getAllThemes();
    },
    fillObjectWithDefaultValue(objectToFill, defaultValuesObject) {
      for (const key of Object.keys(defaultValuesObject)) {
        if(!objectToFill.hasOwnProperty(key)) { // Attribute doesn't exists
          if(defaultValuesObject[key] instanceof Object && !Array.isArray(defaultValuesObject[key])) {
            objectToFill[key] = {};
            this.fillObjectWithDefaultValue(objectToFill[key], defaultValuesObject[key]);
          }else{
            objectToFill[key] = JSON.parse(JSON.stringify(defaultValuesObject[key]));
          }
        }else if(objectToFill[key] instanceof Object && !Array.isArray(objectToFill[key])) { // Attribute exists and is an object, need to verify if sub-attribute exists
          this.fillObjectWithDefaultValue(objectToFill[key], defaultValuesObject[key]);
        }
      }
    },
    getAllBrands() {
      this.$apiInstance
        .getAllBrands()
        .then(
          (data) => {
            this.brands = data;
          },
          (error) => {
            /**
             * ERROR GET BRANDS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    getAllSuppliers() {
      this.$apiInstance.getAllSuppliers()
        .then(
          (data) => {
            this.suppliers = data;
          },
          (error) => {
            /**
             * ERROR GET SUPPLIERS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    getAllOffers() {
      this.$apiInstance.getAdminWarehouseOffers()
        .then(
          (data) => {
            this.offers = data;
          },
          (error) => {
            /**
             * ERROR GET OFFERS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    getAllThemes() {
      this.$apiInstance.getThemes()
        .then(
          (data) => {
            this.themes = data;
          },
          (error) => {
            /**
             * ERROR GET OFFERS
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    validate() {
      this.$refs?.form?.validate();
      this.$refs?.formWarehouseFeatures?.validate();
      this.$refs?.formWarehouseSubscription?.validate();
      this.isValid = this.$refs?.form?.validate() && this.$refs?.formWarehouseFeatures?.validate() && this.$refs?.formWarehouseSubscription?.validate();
      return this.isValid;
    },
    save() {
      if(this.validate()) {
        this.offerObject.features = this.$refs?.formWarehouseFeatures?.save();
        this.offerObject.subscription = this.$refs?.formWarehouseSubscription?.save();
        if(this.createNewObject) {
          this.offerObject.subscription.plan = this.offerObject._id;
        }
        return this.offerObject;
      }else{
        return null;
      }
    }
  },
};
</script>
