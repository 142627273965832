<template>
  <v-form
    v-if="subscriptionObject"
    ref="form"
    v-model="valid"
    lazy-validation
    @input="validate(); $emit('validate')"
  >
    <!-- Subscription -->
    <h2 class="mb-6">
      {{ $t('subscription') }}
    </h2>
    <v-row>
      <v-col v-if="!createNewObject">
        <!-- Plan -->
        <!-- Not show when create a new object -->
        <v-select
          :items="offers"
          :item-text="`title.${$userLocale}`"
          item-value="_id"
          :label="$t('plan')"
          v-model="subscriptionObject.plan"
          outlined
          attach
        >
        </v-select>
      </v-col>
      <v-col>
        <!-- Price -->
        <v-text-field
          v-model.number="subscriptionObject.price"
          :rules="[rules.greaterThanOrEqualZero]"
          :label="$t('price')"
          min="0"
          outlined
          append-icon="fas fa-euro-sign"
        >
        </v-text-field>
      </v-col>
      <v-col>
        <!-- Payment Method -->
        <v-select
          :items="paymentMethods"
          :label="$t('paymentMethod')"
          v-model="subscriptionObject.payment_method"
          outlined
          attach
        >
        </v-select>
      </v-col>
      <v-col>
        <!-- Recurrence -->
        <v-select
          :items="recurrence"
          :label="$t('recurrence')"
          v-model="subscriptionObject.recurrence"
          outlined
          attach
        >
        </v-select>
      </v-col>
      <v-col v-if="!createNewObject">
        <!-- Purchase Order -->
        <!-- Not show when create a new object -->
        <v-text-field
          v-model="subscriptionObject.purchaseOrder"
          :label="$t('presetPurchaseOrder')"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- Code -->
        <v-text-field
          v-model="subscriptionObject.code"
          :label="$t('presetDiscountCode')"
          outlined
        >
        </v-text-field>
      </v-col>
      <v-col>
        <!-- Trial Period -->
        <v-text-field
          v-model.number="subscriptionObject.trial_period"
          :rules="[rules.greaterThanOrEqualZero]"
          :label="$t('trialPeriod')"
          min="0"
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="!createNewObject">
        <!-- Auto Renew -->
        <!-- Not show when create a new object -->
        <v-checkbox
          v-model="subscriptionObject.auto_renewal"
          :label="$t('autoRenewal')"
          inset
          color="success"
        >
        </v-checkbox>
      </v-col>
      <v-col v-if="!createNewObject">
        <!-- Next Billing Date -->
        <!-- Not show when create a new object -->
        <v-text-field
          v-model="subscriptionObject.nextBillingDate"
          :label="$t('nextBillingDate')"
          outlined
          :disabled="true"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
const i18nData = require('./formWarehouseSubscription.i18n');
export default {
  name: 'FormWarehouseSubscription',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * The offer object
     */
    subscription: {
      type: Object,
      required: true
    },
    /**
     * Offers
     */
    offers: {
      type: Array,
      required: true
    },
    /**
     * New object
     * Disable some fields if we add a new object instead of edit a current object
     */
    createNewObject: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    subscription: {
      handler: function() {
        this.init();
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      /**
       * Subscription Object
       */
      subscriptionObject: null,
      /**
       * Form validation status
       */
      valid: false,
      /**
       * Form validation rules
       */
      rules: {
        required: (value) =>
          (value && value.toString().trim() !== '') || this.$t('requiredField'),
        greaterThanOrEqualZero: (value) =>
          (!isNaN(Number(value)) && Number.parseInt(value) >= 0) ||  this.$t('thisFieldShouldBeGreaterThanOrEqualZero')
      },
      /**
       * Payment Method
       */
      paymentMethods: [
        {text: this.$t('CARD'), value: 'CARD'},
        {text: this.$t('TRANSFER'), value: 'TRANSFER'},
        {text: this.$t('MANUAL'), value: 'MANUAL'},
        {text: this.$t('SEPA'), value: 'SEPA'},
        {text: this.$t('AUTO'), value: 'AUTO'},
      ],
      /**
       * Recurrence
       */
      recurrence: [
        {text: this.$t('MONTHLY'), value: 'MONTHLY'},
        {text: this.$t('YEARLY'), value: 'YEARLY'},
      ],
    };
  },
  created() {
    
  },
  methods: {
    init() {
      this.subscriptionObject = JSON.parse(JSON.stringify(this.subscription));
    },
    validate() {
      this.valid = this.$refs?.form?.validate();
      return this.valid;
    },
    save() {
      if(this.validate()) {
        return this.subscriptionObject;
      }else{
        return null;
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>