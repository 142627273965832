var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.featuresObject ? _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    on: {
      "input": function ($event) {
        _vm.validate();
        _vm.$emit('validate');
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('h2', {
    staticClass: "mb-6"
  }, [_vm._v(" " + _vm._s(_vm.$t('features')) + " ")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": true
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('partsQuota'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.quotaGreaterThanZero],
      "required": "",
      "min": "1",
      "max": "1000"
    },
    model: {
      value: _vm.featuresObject.warehousePartQuota,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "warehousePartQuota", _vm._n($$v));
      },
      expression: "featuresObject.warehousePartQuota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": true
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('usersQuota'),
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.quotaGreaterThanZero],
      "required": "",
      "min": "1",
      "max": "1000"
    },
    model: {
      value: _vm.featuresObject.userQuota,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "userQuota", _vm._n($$v));
      },
      expression: "featuresObject.userQuota"
    }
  })], 1), _c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('accessToBCMNetwork')
    },
    model: {
      value: _vm.featuresObject.accessToBCMNetwork,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "accessToBCMNetwork", $$v);
      },
      expression: "featuresObject.accessToBCMNetwork"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('enableTags')
    },
    model: {
      value: _vm.featuresObject.enableTags,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "enableTags", $$v);
      },
      expression: "featuresObject.enableTags"
    }
  })], 1), _c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('enableSerialNumber')
    },
    model: {
      value: _vm.featuresObject.enableSerialNumber,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "enableSerialNumber", $$v);
      },
      expression: "featuresObject.enableSerialNumber"
    }
  })], 1), _c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('forcePurchaseOrder')
    },
    model: {
      value: _vm.featuresObject.forcePurchaseOrder,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "forcePurchaseOrder", $$v);
      },
      expression: "featuresObject.forcePurchaseOrder"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.paymentMethods,
      "label": _vm.$t('defaultPaymentMethod'),
      "required": "",
      "rules": [_vm.rules.required],
      "outlined": "",
      "attach": ""
    },
    model: {
      value: _vm.featuresObject.defaultPaymentMethod,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "defaultPaymentMethod", $$v);
      },
      expression: "featuresObject.defaultPaymentMethod"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('defaultMargin'),
      "outlined": "",
      "rules": [_vm.rules.greaterThanOrEqualZero],
      "required": "",
      "min": "0",
      "append-icon": "fas fa-percentage"
    },
    model: {
      value: _vm.featuresObject.defaultMargin,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "defaultMargin", _vm._n($$v));
      },
      expression: "featuresObject.defaultMargin"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.quoteCalculations,
      "label": _vm.$t('quoteCalculation'),
      "outlined": "",
      "attach": ""
    },
    model: {
      value: _vm.featuresObject.quoteCalculation,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "quoteCalculation", $$v);
      },
      expression: "featuresObject.quoteCalculation"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": true
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('deliveryDiscount'),
      "outlined": "",
      "rules": [_vm.rules.greaterThanOrEqualZero],
      "required": "",
      "min": "0",
      "append-icon": "fas fa-euro-sign"
    },
    model: {
      value: _vm.featuresObject.deliveryDiscount,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "deliveryDiscount", _vm._n($$v));
      },
      expression: "featuresObject.deliveryDiscount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": true
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.themes,
      "outlined": "",
      "attach": "",
      "item-text": "_id",
      "item-value": "_id",
      "label": _vm.$t('theme')
    },
    model: {
      value: _vm.featuresObject.theme,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "theme", $$v);
      },
      expression: "featuresObject.theme"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": true
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.allCountries,
      "outlined": "",
      "chips": "",
      "attach": "",
      "item-text": "name",
      "item-value": "code",
      "small-chips": "",
      "label": _vm.$t('availableDeliveryCountries'),
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend-item",
      fn: function () {
        return [_c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": function ($event) {
              _vm.featuresObject.availableDeliveryCountries.length === _vm.allWorldCountries.length ? _vm.featuresObject.availableDeliveryCountries = [] : _vm.featuresObject.availableDeliveryCountries = _vm.allWorldCountries;
            }
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "color": _vm.featuresObject.availableDeliveryCountries.length === _vm.allWorldCountries.length ? 'success' : ''
          }
        }, [_vm._v(" fas fa-globe ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          class: _vm.featuresObject.availableDeliveryCountries.length === _vm.allCountries.length ? 'success--text' : ''
        }, [_vm._v(" " + _vm._s(_vm.$t('allCountriesInTheWorld')) + " ")])], 1)], 1), _c('v-list-item', {
          attrs: {
            "ripple": ""
          },
          on: {
            "click": function ($event) {
              _vm.featuresObject.availableDeliveryCountries.length === _vm.allEuropeCountries.length ? _vm.featuresObject.availableDeliveryCountries = [] : _vm.featuresObject.availableDeliveryCountries = _vm.allEuropeCountries;
            }
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "color": _vm.featuresObject.availableDeliveryCountries.length === _vm.allEuropeCountries.length ? 'success' : ''
          }
        }, [_vm._v(" fas fa-globe-europe ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          class: _vm.featuresObject.availableDeliveryCountries.length === _vm.allEuropeCountries.length ? 'success--text' : ''
        }, [_vm._v(" " + _vm._s(_vm.$t('allCountriesInEurope')) + " ")])], 1)], 1), _c('v-divider', {
          staticClass: "mt-2"
        })];
      },
      proxy: true
    }], null, false, 3063597158),
    model: {
      value: _vm.featuresObject.availableDeliveryCountries,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject, "availableDeliveryCountries", $$v);
      },
      expression: "featuresObject.availableDeliveryCountries"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('enableSharing')
    },
    model: {
      value: _vm.featuresObject.sharing.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject.sharing, "enabled", $$v);
      },
      expression: "featuresObject.sharing.enabled"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": true
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id",
      "multiple": "",
      "outlined": "",
      "chips": "",
      "attach": "",
      "label": _vm.$t('canShareWith'),
      "disabled": !_vm.featuresObject.sharing.enabled
    },
    model: {
      value: _vm.featuresObject.sharing.sharingWith,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject.sharing, "sharingWith", $$v);
      },
      expression: "featuresObject.sharing.sharingWith"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": true
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.suppliers,
      "item-text": "name",
      "item-value": "_id",
      "multiple": "",
      "outlined": "",
      "chips": "",
      "attach": "",
      "label": _vm.$t('BCMPrivateNetwork'),
      "menu-props": {
        bottom: true
      },
      "clearable": "",
      "auto": ""
    },
    on: {
      "input": _vm.selectedPrivateSuppliersChanged
    },
    model: {
      value: _vm.selectedPrivateSuppliers,
      callback: function ($$v) {
        _vm.selectedPrivateSuppliers = $$v;
      },
      expression: "selectedPrivateSuppliers"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersPrivateSuppliers,
      "items": _vm.featuresObject.privateSuppliers
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var _vm$suppliersMap$item;
        var item = _ref.item;
        return [_c('span', [_vm._v(" " + _vm._s((_vm$suppliersMap$item = _vm.suppliersMap[item.supplier]) === null || _vm$suppliersMap$item === void 0 ? void 0 : _vm$suppliersMap$item.name) + " ")])];
      }
    }, {
      key: `item.billing`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-simple-checkbox', {
          attrs: {
            "ripple": false
          },
          model: {
            value: item.billing,
            callback: function ($$v) {
              _vm.$set(item, "billing", $$v);
            },
            expression: "item.billing"
          }
        })];
      }
    }], null, true)
  })], 1)], 1), !_vm.startupFeatureAvailable ? _c('v-row', [_c('v-col', [_vm._v(" " + _vm._s(_vm.$t('startupFeatureNotAvailable')) + " ")])], 1) : _c('v-row', [_c('v-col', [_c('v-switch', {
    attrs: {
      "label": _vm.$t('enableStartupFeature')
    },
    model: {
      value: _vm.startupFeatureEnabled,
      callback: function ($$v) {
        _vm.startupFeatureEnabled = $$v;
      },
      expression: "startupFeatureEnabled"
    }
  })], 1), _c('v-col', [_c('v-switch', {
    attrs: {
      "disabled": !_vm.startupFeatureEnabled,
      "label": _vm.$t('startupIsManager')
    },
    model: {
      value: _vm.featuresObject.startup.isManager,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject.startup, "isManager", $$v);
      },
      expression: "featuresObject.startup.isManager"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "attach": "",
      "label": _vm.$t('startupManagedBy'),
      "disabled": !_vm.startupFeatureEnabled || _vm.featuresObject.startup.isManager
    },
    model: {
      value: _vm.featuresObject.startup.managedBy,
      callback: function ($$v) {
        _vm.$set(_vm.featuresObject.startup, "managedBy", $$v);
      },
      expression: "featuresObject.startup.managedBy"
    }
  })], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }