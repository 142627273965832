<template>
  <v-form
    v-if="featuresObject"
    ref="form"
    v-model="valid"
    lazy-validation
    @input="validate(); $emit('validate')"
  >
    <!-- Features -->
    <h2 class="mb-6">
      {{ $t('features') }}
    </h2>
    <v-row>
      <v-col
        cols="6"
        :md="true"
      >
        <!-- Part Quota -->
        <v-text-field
          v-model.number="featuresObject.warehousePartQuota"
          :label="$t('partsQuota')"
          outlined
          :rules="[rules.required, rules.quotaGreaterThanZero]"
          required
          min="1"
          max="1000"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        :md="true"
      >
        <!-- User Quota -->
        <v-text-field
          v-model.number="featuresObject.userQuota"
          :label="$t('usersQuota')"
          outlined
          :rules="[rules.required, rules.quotaGreaterThanZero]"
          required
          min="1"
          max="1000"
        ></v-text-field>
      </v-col>
      <v-col>
        <!-- Enable Access BCM Community Network Switch -->
        <v-switch
          v-model="featuresObject.accessToBCMNetwork"
          :label="$t('accessToBCMNetwork')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- Enable Tags Switch -->
        <v-switch
          v-model="featuresObject.enableTags"
          :label="$t('enableTags')"
        ></v-switch>
      </v-col>
      <v-col>
        <!-- Enable Serial Number Switch -->
        <v-switch
          v-model="featuresObject.enableSerialNumber"
          :label="$t('enableSerialNumber')"
        ></v-switch>
      </v-col>
      <v-col>
        <!-- Force Purchase Order Switch -->
        <v-switch
          v-model="featuresObject.forcePurchaseOrder"
          :label="$t('forcePurchaseOrder')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- Default Payment Method For Parts -->
        <v-select
          :items="paymentMethods"
          :label="$t('defaultPaymentMethod')"
          v-model="featuresObject.defaultPaymentMethod"
          required
          :rules="[rules.required]"
          outlined
          attach
        >
        </v-select>
      </v-col>
      <v-col>
        <!-- Default Margin -->
        <v-text-field
          v-model.number="featuresObject.defaultMargin"
          :label="$t('defaultMargin')"
          outlined
          :rules="[rules.greaterThanOrEqualZero]"
          required
          min="0"
          append-icon="fas fa-percentage"
        ></v-text-field>
      </v-col>
      <v-col>
        <!-- Quote Calculation Mode -->
        <v-select
          :items="quoteCalculations"
          :label="$t('quoteCalculation')"
          v-model="featuresObject.quoteCalculation"
          outlined
          attach
        >
        </v-select>
      </v-col>
      <v-col
        cols="12"
        :md="true"
      >
        <!-- Delivery Discount -->
        <v-text-field
          v-model.number="featuresObject.deliveryDiscount"
          :label="$t('deliveryDiscount')"
          outlined
          :rules="[rules.greaterThanOrEqualZero]"
          required
          min="0"
          append-icon="fas fa-euro-sign"
        ></v-text-field>
      </v-col>
      <v-col cols="12" :md="true">
        <v-autocomplete
          v-model="featuresObject.theme"
          :items="themes"
          outlined
          attach
          item-text="_id"
          item-value="_id"
          :label="$t('theme')"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        :md="true"
      >
        <!-- Available Delivery Country -->
        <v-autocomplete
          v-model="featuresObject.availableDeliveryCountries"
          :items="allCountries"
          outlined
          chips
          attach
          item-text="name"
          item-value="code"
          small-chips
          :label="$t('availableDeliveryCountries')"
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @click="
                featuresObject.availableDeliveryCountries.length ===
                  allWorldCountries.length
                  ? (featuresObject.availableDeliveryCountries = [])
                  : (featuresObject.availableDeliveryCountries = allWorldCountries)
              "
            >
              <v-list-item-action>
                <v-icon
                  :color="
                    featuresObject.availableDeliveryCountries.length ===
                      allWorldCountries.length
                      ? 'success'
                      : ''
                  "
                >
                  fas fa-globe
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    featuresObject.availableDeliveryCountries.length ===
                      allCountries.length
                      ? 'success--text'
                      : ''
                  "
                >
                  {{ $t('allCountriesInTheWorld') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              ripple
              @click="
                featuresObject.availableDeliveryCountries.length ===
                  allEuropeCountries.length
                  ? (featuresObject.availableDeliveryCountries = [])
                  : (featuresObject.availableDeliveryCountries = allEuropeCountries)
              "
            >
              <v-list-item-action>
                <v-icon
                  :color="
                    featuresObject.availableDeliveryCountries.length ===
                      allEuropeCountries.length
                      ? 'success'
                      : ''
                  "
                >
                  fas fa-globe-europe
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    featuresObject.availableDeliveryCountries.length ===
                      allEuropeCountries.length
                      ? 'success--text'
                      : ''
                  "
                >
                  {{ $t('allCountriesInEurope') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- Enable Sharing Switch -->
        <v-switch
          v-model="featuresObject.sharing.enabled"
          :label="$t('enableSharing')"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        :md="true"
      >
        <!-- Sharing With -->
        <v-autocomplete
          v-model="featuresObject.sharing.sharingWith"
          :items="brands"
          item-text="name"
          item-value="_id"
          multiple
          outlined
          chips
          attach
          :label="$t('canShareWith')"
          :disabled="!featuresObject.sharing.enabled"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!-- PRIVATE SUPPLIERS -->
    <v-row>
      <v-col
        cols="12"
        :md="true"
      >
        <!-- Private Supplier Selector -->
        <v-autocomplete
          v-model="selectedPrivateSuppliers"
          :items="suppliers"
          item-text="name"
          item-value="_id"
          multiple
          outlined
          chips
          attach
          :label="$t('BCMPrivateNetwork')"
          :menu-props="{bottom: true}"
          clearable
          auto
          @input="selectedPrivateSuppliersChanged"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headersPrivateSuppliers"
          :items="featuresObject.privateSuppliers"
        >
          <template v-slot:[`item.name`]="{ item }">
            <span>
              {{ suppliersMap[item.supplier]?.name }}
            </span>
          </template>
          <template v-slot:[`item.billing`]="{ item }">
            <v-simple-checkbox
              :ripple="false"
              v-model="item.billing"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- STARTUP FEATURE -->
    <v-row v-if="!startupFeatureAvailable">
      <v-col>
        {{ $t('startupFeatureNotAvailable') }}        
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <!-- Enable Startup Switch -->
        <v-switch
          v-model="startupFeatureEnabled"
          :label="$t('enableStartupFeature')"
        ></v-switch>
      </v-col>
      <v-col>
        <!-- Enable Startup Manager Switch -->
        <v-switch
          v-model="featuresObject.startup.isManager"
          :disabled="!startupFeatureEnabled"
          :label="$t('startupIsManager')"
        ></v-switch>
      </v-col>
      <v-col>
        <!-- Startup Managed By -->
        <v-autocomplete
          v-model="featuresObject.startup.managedBy"
          :items="brands"
          item-text="name"
          item-value="_id"
          outlined
          attach
          :label="$t('startupManagedBy')"
          :disabled="!startupFeatureEnabled || featuresObject.startup.isManager"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import countryList from 'country-list';
const i18nData = require('./formWarehouseFeatures.i18n');
export default {
  name: 'FormWarehouseFeatures',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * The offer object
     */
    features: {
      type: Object,
      required: true
    },
    /**
     * All brands
     */
    brands: {
      type: Array,
      required: true
    },
    /**
     * All suppliers
     */
    suppliers: {
      type: Array,
      required: true
    },
    /**
     * All theme
     */
    themes: {
      type: Array,
      required: true
    },
    /**
     * Startup feature available
     * if subscription.plan === 'STARTUP'
     */
    startupFeatureAvailable: {
      type: Boolean,
      default: false
    },
    /**
     * New object
     * Disable some fields if we add a new object instead of edit a current object
     */
    createNewObject: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    features: {
      handler: function() {
        this.init();
      },
      deep: true,
      immediate: true
    },
    suppliers: {
      handler: function() {
        this.generateSuppliersMapping();
      },
      deep: true,
      immediate: false,
    }
  },
  data() {
    return {
      /**
       * Features Object
       */
      featuresObject: null,
      /**
       * Private Supplier
       */
      headersPrivateSuppliers: [
        { text: this.$t('supplier'), value: 'name' },
        { text: this.$t('billing'), value: 'billing' },
      ],
      suppliersMap: {},
      selectedPrivateSuppliers: [],
      /**
       * Form validation status
       */
      valid: false,
      /**
       * Form validation rules
       */
      rules: {
        required: (value) =>
          (value && value.toString().trim() !== '') || this.$t('requiredField'),
        quotaGreaterThanZero: (value) =>
          (!isNaN(Number(value)) && Number.parseInt(value) > 0) ||  this.$t('thisFieldShouldBeGreaterThanZero'),
        greaterThanOrEqualZero: (value) =>
          (!isNaN(Number(value)) && Number.parseInt(value) >= 0) ||  this.$t('thisFieldShouldBeGreaterThanOrEqualZero')
      },
      /**
       * Startup Feature
       */
      startupFeatureEnabled: false,
      /**
       * Payment Method
       */
      paymentMethods: [
        {text: this.$t('CARD'), value: 'card'},
        {text: this.$t('TRANSFER'), value: 'transfer'},
        {text: this.$t('MANUAL'), value: 'manual'},
        {text: this.$t('SEPA'), value: 'sepa'},
        {text: this.$t('AUTO'), value: 'auto'},
      ],
      /**
       * Quote calculation mode
       */
      quoteCalculations: [
        {text: this.$t('REAL_PRICE'), value: 'REAL_PRICE'},
        {text: this.$t('GLOBAL_DISCOUNT'), value: 'GLOBAL_DISCOUNT'},
      ],
      /**
       * Delivery Countries
       */
      allCountries: countryList.getData(),
      allEuropeCountries: [
        'DE',
        'AT',
        'BE',
        'BG',
        'CY',
        'HR',
        'DK',
        'ES',
        'EE',
        'FI',
        'FR',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'MT',
        'LU',
        'NL',
        'PL',
        'PT',
        'CZ',
        'RO',
        'SK',
        'SI',
        'SE',
        'CH',
        'LI',
        'AD',
        'MC',
        'VA',
        'SM',
      ],
      allWorldCountries: countryList.getCodes(),
    };
  },
  created() {
    this.generateSuppliersMapping();
  },
  methods: {
    init() {
      this.featuresObject = JSON.parse(JSON.stringify(this.features));
      this.generateSuppliersMapping();
    },
    generateSuppliersMapping() {
      /**
       * Create supplier map for privateSupplier
       */
      for(const supplier of this.suppliers) {
        this.suppliersMap[supplier._id] = supplier;
      }
      /**
       * Fill selectedPrivateSuppliers with private suppliers
       */
      const bcmPrivateSupplierNetwork = [];
      for(const supplierData of this.featuresObject.privateSuppliers) {
        bcmPrivateSupplierNetwork.push(supplierData.supplier);
      }
      this.selectedPrivateSuppliers = bcmPrivateSupplierNetwork;
    },
    validate() {
      this.valid = this.$refs?.form?.validate();
      return this.valid;
    },
    save() {
      if(this.validate()) {
        return this.featuresObject;
      }else{
        return null;
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    /**
     * Private suppliers
     */
    selectedPrivateSuppliersChanged() {
      /**
       * Remove unselected suppliers
       */
      this.featuresObject.privateSuppliers = this.featuresObject.privateSuppliers.filter(psInfo => {
        return this.selectedPrivateSuppliers.includes(psInfo.supplier);
      });

      const currentPrivateSuppliers = new Set(this.featuresObject.privateSuppliers.map(psInfo => {return psInfo.supplier;}));
      /**
       * Add freshly added suppliers
       */
      this.featuresObject.privateSuppliers = [...this.featuresObject.privateSuppliers, ...this.selectedPrivateSuppliers.filter(sID => {
        return !currentPrivateSuppliers.has(sID);
      }).map(sID => {
        return {
          supplier: sID,
          billing: false
        };
      })];
    },
  },
};
</script>