var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('offers')))]), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-5",
    attrs: {
      "color": "info",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.dialogAddNewOfferTemplate = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('addNewOffer')) + " ")], 1), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('refreshPage'),
      "ripple": false
    },
    on: {
      "click": _vm.init
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-10"
  }), _c('v-dialog', {
    attrs: {
      "width": "1024",
      "persistent": ""
    },
    model: {
      value: _vm.dialogAddNewOfferTemplate,
      callback: function ($$v) {
        _vm.dialogAddNewOfferTemplate = $$v;
      },
      expression: "dialogAddNewOfferTemplate"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('addNewOfferTemplate')) + " ")]), _c('v-divider', {
    staticClass: "mx-4 mb-4"
  }), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('warehouse-offer', {
    ref: "formOffer",
    attrs: {
      "offer": {},
      "create-new-object": true
    }
  })], 1), _c('v-divider', {
    staticClass: "mx-4"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.dialogAddNewOfferTemplate = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "ripple": false
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1)], 1)], 1), _vm.offers ? _c('v-card', [_c('v-card-title', [_c('v-row', [_c('v-col', [_c('DataTableFilter', {
    ref: "dataTableFilter",
    attrs: {
      "width-of-filters-titles": "1",
      "filters": _vm.filterObject
    },
    on: {
      "update": _vm.updateFilter
    }
  })], 1), _c('v-col', {
    staticClass: "col-12 col-md-4"
  }, [_c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "cursorTable",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.offers,
      "search": _vm.search,
      "custom-filter": _vm.customFilter,
      "sort-by": "_id",
      "sort-desc": false,
      "loading": _vm.loading,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.display`,
      fn: function (_ref) {
        var item = _ref.item;
        return [item.display ? [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "success"
          }
        }, [_vm._v(" fas fa-unlock ")])] : [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" fas fa-lock ")])]];
      }
    }, {
      key: `item.subscription.price`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.subscription.price > 0 ? _c('span', [_vm._v(" " + _vm._s(_vm.$n(item.subscription.price, 'currency', _vm.$userLocale)) + " ")]) : _c('span', {
          staticClass: "font-weight-light"
        }, [_vm._v(" " + _vm._s(_vm.$t('free')) + " ")])];
      }
    }, {
      key: `item.subscription.recurrence`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.$t(item.subscription.recurrence)) + " ")];
      }
    }, {
      key: `item.subscription.trial`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.subscription.trial > 0 ? _c('span', [_vm._v(" " + _vm._s(item.subscription.trial) + " ")]) : _c('span', [_c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("fas fa-times")])], 1)];
      }
    }, {
      key: `item.features.accessToBCMNetwork`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.features.accessToBCMNetwork ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")])];
      }
    }, {
      key: `item.features.enableTags`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item.features.enableTags ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")])];
      }
    }, {
      key: `item.features.enableSerialNumber`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.features.enableSerialNumber ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")])];
      }
    }, {
      key: `item.features.privateSuppliers.length`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.features.privateSuppliers.length > 0 ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")])];
      }
    }, {
      key: `item.features.sharing.enabled`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item.features.sharing.enabled ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" fas fa-check ")]) : _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" fas fa-times ")])];
      }
    }, {
      key: `item.action`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('v-dialog', {
          attrs: {
            "width": "1024",
            "persistent": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref11) {
              var on = _ref11.on,
                attrs = _ref11.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "color": "primary",
                  "title": _vm.$t('edit'),
                  "outlined": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" fas fa-pen ")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.dialogEditOfferTemplate[item._id],
            callback: function ($$v) {
              _vm.$set(_vm.dialogEditOfferTemplate, item._id, $$v);
            },
            expression: "dialogEditOfferTemplate[item._id]"
          }
        }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('modifyOffer')) + " - " + _vm._s(item._id) + " ")]), _c('v-divider', {
          staticClass: "mx-4 mb-4"
        }), _c('v-card-text', {
          staticClass: "pb-0"
        }, [_c('warehouse-offer', {
          ref: `formOfferEdit_${item._id}`,
          attrs: {
            "offer": item,
            "create-new-object": true
          }
        })], 1), _c('v-divider', {
          staticClass: "mx-4"
        }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "color": "error",
            "ripple": false
          },
          on: {
            "click": function ($event) {
              _vm.dialogEditOfferTemplate[item._id] = false;
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
          staticClass: "text-none",
          attrs: {
            "color": "success",
            "ripple": false
          },
          on: {
            "click": function ($event) {
              _vm.saveEditOffer(item._id);
              _vm.dialogEditOfferTemplate[item._id] = false;
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Save')) + " ")], 1)], 1)], 1)], 1), _c('v-dialog', {
          attrs: {
            "persistent": "",
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref12) {
              var on = _ref12.on,
                attrs = _ref12.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "error",
                  "title": _vm.$t('delete'),
                  "outlined": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v(" fas fa-trash ")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.dialogConfirmDeleteOffer[item._id],
            callback: function ($$v) {
              _vm.$set(_vm.dialogConfirmDeleteOffer, item._id, $$v);
            },
            expression: "dialogConfirmDeleteOffer[item._id]"
          }
        }, [_c('v-card', [_c('v-card-title', {
          staticClass: "text-h5"
        }, [_vm._v(" " + _vm._s(_vm.$t('deleteThisOffer?')) + " ")]), _c('v-card-text', [_c('b', [_vm._v(_vm._s(_vm.$t('offerId')))]), _vm._v(" " + _vm._s(item._id))]), _c('v-card-text', [_c('b', [_vm._v(_vm._s(_vm.$t('offerTitle')))]), _vm._v(" " + _vm._s(item.title[_vm.$userLocale]))]), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.dialogConfirmDeleteOffer[item._id] = false;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "error",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteOffer(item._id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('delete')) + " ")])], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }