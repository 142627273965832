<template lang="html" src="./pageOffers.template.vue"></template>

<script>
import { ApiErrorParser, DataTableFilter, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';
import WarehouseOffer from '../../components/warehouseOffer/WarehouseOffer';

const i18nData = require('./pageOffers.i18n');

export default {
  name: 'PageOffers',
  components: { WarehouseOffer, DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Dialog new offer template
       */
      dialogAddNewOfferTemplate: false,
      /**
       * Dialog edit offer
       */
      dialogEditOfferTemplate: [],
      /**
       * Dialog confirm delete offer
       */
      dialogConfirmDeleteOffer: [],
      /**
       * Datatable offers
       */
      headers: [],
      offers: [],
      search: '',
      loading: false,
      typeFilter: [],
      filterObject: {
        offer: [
          {
            name: this.$t('publicOffer'),
            value: 'public', 
            iconColor: 'success',
            icon: 'fas fa-unlock',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
          {
            name: this.$t('privateOffer'),
            value: 'private', 
            iconColor: 'primary',
            icon: 'fas fa-lock',
            number: 0,
            get selected() {
              return this.number > 0;
            }
          },
        ]
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('offer'),
        value: '_id',
      },
      {
        text: this.$t('public'),
        value: 'display',
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      },
      {
        text: this.$t('price'),
        value: 'subscription.price',
        align: 'left',
      },
      {
        text: this.$t('recurrence'),
        value: 'subscription.recurrence',
      },
      {
        text: this.$t('trial'),
        value: 'subscription.trial',
      },
      { 
        text: this.$t('BCMNetwork'), 
        value: 'features.accessToBCMNetwork' 
      },
      { 
        text: this.$t('tags'), 
        value: 'features.enableTags' 
      },
      { 
        text: this.$t('serialNumber'), 
        value: 'features.enableSerialNumber' 
      },
      { 
        text: this.$t('privateSupplier'), 
        value: 'features.privateSuppliers.length' 
      },
      { 
        text: this.$t('sharing'), 
        value: 'features.sharing.enabled' 
      },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
  },
  methods: {
    init() {
      this.offers = [];
      this.typeFilter = [],
      this.loading = true;
      this.getAllWarehouseAdminOffers();
    },
    getAllWarehouseAdminOffers() {
      this.$apiInstance.getAdminWarehouseOffers()
        .then((data) => {
          this.offers = data;
          /**
             * Reset Filter Object
             */
          for (const key in this.filterObject) {
            for (const obj of this.filterObject[key]) {
              obj.number = 0;
            }
          }
          this.offers.forEach(offer =>{
            this.filterObject.offer.find(x => (x.value === 'public' && offer.display) || (x.value === 'private' && !offer.display)).number++;
          });
        }, (error) => {
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    updateFilter(updatedFilter) {
      if(updatedFilter.offer) {
        this.typeFilter = updatedFilter.offer;
      }
    },
    save() {
      const offer = this.$refs.formOffer.save();
      if(!offer) {
        this.isValid = false;
      }else{
        /**
         * Save offer
         */
        this.$apiInstance.addAdminWarehouseOffer(offer)
          .then((data)=>{
            this.dialogAddNewOfferTemplate = false;
            this.$notification.notify('SUCCESS',this.$t('offerAdded'));
            this.init();
          })
          .catch((error)=>{
            /**
             * ERROR ADD OFFER
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });

        /**
         * Close dialog
         */
      }
    },
    deleteOffer(id) {
      this.$apiInstance.deleteAdminWarehouseOffer(id)
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('offerDeleted'));
            this.init();
          }, (error) => {
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });
    },
    saveEditOffer(id) {
      const offer = this.$refs[`formOfferEdit_${id}`].save();
      if(!offer) {
        this.isValid = false;
      }else{
        /**
         * Save offer
         */
        this.$apiInstance.modifyAdminWarehouseOffer(id, offer)
          .then((data)=>{
            this.dialogConfirmDeleteOffer[id] = false;
            this.$notification.notify('SUCCESS',this.$t('offerModified'));
            this.init();
          })
          .catch((error)=>{
            /**
             * ERROR ADD OFFER
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          });

        /**
         * Close dialog
         */
      }
    }
  },
};
</script>
